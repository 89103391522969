import { useNuxtApp } from 'nuxt/app'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    // User
    inited: false,
    session: null,
    user: null,

    loading_resources: false,
    grid_cols: useLocalStorage('ms-grid_cols', 6),
    grid_cols_alt: useLocalStorage('ms-grid_cols_alt', 6),

    // Team
    teams: [],
    selected_team_id: useLocalStorage('ms-selected_team_id', null),

    // Folder
    folders: [],

    // Files
    files: [],
    selected_files: [],

    // Search
    search_files: [],

    // Model
    models: [],
    model_filters: useLocalStorage('ms-model_filters', {
      base: []
    }),

    // Prediction
    predictions: [],

    // Editor project
    editor_projects: [],

    // Wildcards
    wildcards: []
  }),
  actions: {
    reset() {
      this.user = null

      this.loading_resources = false
      this.grid_cols = 6
      this.grid_cols_alt = 6

      this.teams = []
      this.selected_team_id = null

      this.folders = []

      this.files = []
      this.selected_files = []

      this.search_files = []

      this.models = []
      this.selected_model_id = null
      this.model_filters = {
        base: []
      }

      this.predictions = []

      this.editor_projects = []

      this.wildcards = []
    },

    // Misc.
    setLoadingResources(val) {
      this.loading_resources = val
    },
    setGridCols(val) {
      this.grid_cols = val
    },
    setGridColsAlt(val) {
      this.grid_cols_alt = val
    },
    async getTeamName(name) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team-name', {
        method: 'GET',
        query: {
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async getTeamUsage() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/usage', {
        method: 'GET',
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async postToDiscord({ file_id }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/discord', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          file_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },

    // Onboarding
    async createOnboarding() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/onboarding', {
        method: 'POST'
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },

    // Stripe
    async getSubscription() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/subscription', {
        method: 'GET',
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createCheckout({ price, cost }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/checkout', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          price,
          cost
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createCustomerPortal() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/stripe/customer-portal', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },

    // Team
    async createTeam({ name, picture, display_name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team', {
        method: 'POST',
        body: {
          name,
          picture,
          display_name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },
    async updateTeam({ name, picture, display_name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          name,
          picture,
          display_name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },
    async listTeams() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/team')

      if (error) {
        throw new Error(error)
      }

      this.teams = data
    },
    setSelectedTeamID(id) {
      this.selected_team_id = id
    },
    upsertLocalTeam(team) {
      const index = this.teams.findIndex((i) => i.id === team?.id)
      if (index !== -1) {
        this.teams[index] = team
      } else {
        this.teams.push(team)
      }
    },
    deleteLocalTeam({ id }) {
      const index = this.teams.findIndex((team) => team.id === id)
      if (index !== -1) {
        this.teams.splice(index, 1)
      }
    },

    // Folder
    async createFolder({ inode_id, name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data)

      return data
    },
    async getFolder({ folder_id }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'GET',
        query: {
          team_id: this.selected_team_id,
          folder_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data[0])
    },
    async updateFolder({ id, inode_id, name, privacy }) {
      // Eager
      this.upsertLocalFolder({ id, inode_id, name, privacy })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          folder_id: id,
          inode_id,
          name,
          privacy
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFolder(data)

      // Edge case where selected folder isn't updated properly
      // if (this.selected_folder?.id === data?.id) {
      //   this.setSelectedFolder(data)
      //}

      return data
    },
    async deleteFolder({ id }) {
      this.deleteLocalFolder({ id })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          folder_id: id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listFolders() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/folder', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.folders = data
    },
    upsertLocalFolder(folder) {
      const index = this.folders.findIndex((i) => i.id === folder?.id)
      if (index >= 0) {
        // We don't use merge here bcs folders are special
        if (folder?.inode_id || folder?.inode_id === null) {
          this.folders[index].inode_id = folder?.inode_id
        }
        if (folder?.name) {
          this.folders[index].name = folder?.name
        }
        if (folder?.privacy) {
          this.folders[index].privacy = folder?.privacy
        }
        if (folder?.file_count >= 0) {
          this.folders[index].file_count = folder?.file_count
        }
      } else {
        this.folders.push(folder)
      }
    },
    deleteLocalFolder({ id }) {
      const index = this.folders.findIndex((folder) => folder.id === id)
      if (index !== -1) {
        this.folders.splice(index, 1)
      }
    },

    // File
    async createFile({ inode_id, name, metadata }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          name,
          metadata
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async updateFile({ id, inode_id, name, privacy }) {
      // Eager
      this.upsertLocalFile({ id, inode_id, name, privacy }, true)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          file_id: id,
          inode_id,
          name,
          privacy
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalFile(data)

      return data
    },
    async updateFiles(files) {
      // Eager
      for (const file of files) {
        this.upsertLocalFile(file, true)
      }

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/files', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          files
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async deleteFiles(files) {
      files.forEach((file) => this.deleteLocalFile(file))

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          file_ids: files.map((file) => file?.id)
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listFiles({ inode_id, model_id, privacy, page = 0, signal = null }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/file', {
        query: {
          team_id: this.selected_team_id,
          inode_id,
          model_id,
          privacy,
          page
        },
        signal
      })

      if (error) {
        throw new Error(error)
      }

      this.files.push(...data)

      return data
    },
    setFiles(val) {
      this.files = val
    },
    setSelectedFiles(val) {
      this.selected_files = val
    },
    upsertLocalFile(file, merge = false) {
      const index = this.files.findIndex((i) => i.id === file?.id)
      if (index !== -1) {
        if (merge) {
          this.files[index] = { ...this.files[index], ...file }
        } else {
          this.files[index] = file
        }
      } else {
        this.files.push(file)
      }
    },
    deleteLocalFile({ id }) {
      const index = this.files.findIndex((file) => file.id === id)
      if (index !== -1) {
        this.files.splice(index, 1)
      }
    },

    // Training file
    async createTrainingFile({ model_id, name, metadata }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id,
          name,
          metadata
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async createTrainingFileCopy({ model_id, files }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file-copy', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id,
          files
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async updateTrainingFile({ id, captions }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          training_file_id: id,
          captions
        }
      })

      if (error) {
        throw new Error(error)
      }

      // this.upsertLocalTrainingFile(data)

      return data
    },
    async deleteTrainingFiles(training_files) {
      training_files.forEach((training_file) =>
        this.deleteLocalTrainingFile(training_file)
      )

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training-file', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          training_files
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalTrainingFile(training_file) {
      // First, find model
      const modelIndex = this.models.findIndex(
        (i) => i.id === training_file?.model_id
      )

      if (modelIndex !== -1) {
        // Then, find training file
        const trainingFileIndex = this.models[
          modelIndex
        ].training_files.findIndex((i) => i.id === training_file?.id)

        if (trainingFileIndex !== -1) {
          this.models[modelIndex].training_files[trainingFileIndex] =
            training_file
        } else {
          this.models[modelIndex].training_files.push(training_file)
        }
      }
    },
    deleteLocalTrainingFile({ id, model_id }) {
      // First, find model
      const modelIndex = this.models.findIndex((i) => i.id === model_id)
      if (modelIndex !== -1) {
        // Then, find training file
        const trainingFileIndex = this.models[
          modelIndex
        ].training_files.findIndex((i) => i.id === id)

        if (trainingFileIndex !== -1) {
          this.models[modelIndex].training_files.splice(trainingFileIndex, 1)
        }
      }
    },

    // Search
    async search({ inode_id, query }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/search', {
        query: {
          team_id: this.selected_team_id,
          inode_id,
          query
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.search_files = data
    },
    setSearchFiles(val) {
      this.search_files = val
    },
    deleteLocalSearchFile({ id }) {
      const index = this.search_files.findIndex((file) => file.id === id)
      if (index !== -1) {
        this.search_files.splice(index, 1)
      }
    },

    // Model
    async createModel({ type }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          type
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalModel(data)

      return data
    },
    async updateModel({
      id,
      base,
      name,
      description,
      privacy,
      tags,
      picture,
      trainings,
      metadata
    }) {
      // Eager
      this.upsertLocalModel(
        { id, base, name, description, tags, privacy, metadata },
        true
      )

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          model_id: id,
          base,
          name,
          description,
          privacy,
          tags,
          picture,
          trainings,
          metadata
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalModel(data)

      return data
    },
    async getModel({ model_id }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        query: {
          team_id: this.selected_team_id,
          model_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listModels() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.models = data
    },
    async deleteModel(model) {
      // Eager
      this.deleteLocalModel(model)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/model', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          model_id: model?.id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalModel(model, merge = false) {
      const index = this.models.findIndex((i) => i.id === model?.id)
      if (index !== -1) {
        if (merge) {
          const filteredModel = Object.fromEntries(
            Object.entries(model).filter(([_, value]) => value !== undefined)
          )
          this.models[index] = { ...this.models[index], ...filteredModel }
        } else {
          this.models[index] = model
        }
      } else {
        this.models.push(model)
      }
    },
    deleteLocalModel({ id }) {
      const index = this.models.findIndex((model) => model.id === id)
      if (index !== -1) {
        this.models.splice(index, 1)
      }
    },

    // Prediction
    async createPrediction({
      inode_id,
      prediction,
      model_id,
      trainings,
      capability,
      input,
      dry_run = false
    }) {
      if (dry_run === true) {
        const { $fetch } = useNuxtApp()
        const { data, error } = await $fetch('/api/prediction', {
          method: 'POST',
          body: {
            team_id: this.selected_team_id,
            model_id,
            trainings,
            capability,
            input,
            dry_run
          }
        })

        if (error) {
          throw new Error(error)
        }

        return data
      }

      // Eager
      this.upsertLocalPrediction(prediction)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/prediction', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          inode_id,
          model_id,
          trainings,
          capability,
          input
        }
      })

      if (error) {
        this.upsertLocalPrediction({ ...prediction, status: 'failed' })
        throw new Error(error)
      }

      // Swap is used since ID has changed
      this.swapLocalPrediction(prediction, data)
    },
    setPredictions(val) {
      this.predictions = val
    },
    upsertLocalPrediction(prediction) {
      const index = this.predictions.findIndex((i) => i.id === prediction?.id)
      if (index !== -1) {
        this.predictions[index] = prediction
      } else {
        this.predictions.unshift(prediction)
      }
    },
    swapLocalPrediction(old_prediction, new_prediction) {
      const oldPredictionIndex = this.predictions.findIndex(
        (i) => i.id === old_prediction?.id
      )
      const newPredictionIndex = this.predictions.findIndex(
        (i) => i.id === new_prediction?.id
      )

      // Sometimes the new prediction got updated via a broadcast before we got here.
      // If the new prediction is already in the list, it's newer and we should not overwrite it.
      if (newPredictionIndex !== -1) {
        // But we need to remove the old one
        if (oldPredictionIndex !== -1) {
          this.predictions.splice(oldPredictionIndex, 1)
        }

        // Otherwise, we need to swap the old one with the new one
      } else {
        if (oldPredictionIndex !== -1) {
          this.predictions[oldPredictionIndex] = new_prediction
        } else {
          this.predictions.push(new_prediction)
        }
      }
    },

    // Training
    async createTraining({ model_id, dry_run }) {
      if (dry_run === true) {
        const { $fetch } = useNuxtApp()
        const { data, error } = await $fetch('/api/training', {
          method: 'POST',
          body: {
            team_id: this.selected_team_id,
            model_id,
            dry_run
          }
        })

        if (error) {
          throw new Error(error)
        }

        return data
      }

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/training', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          model_id
        }
      })

      if (error) {
        this.upsertLocalModel({ model_id, status: 'draft' })
        throw new Error(error)
      }

      return data
    },

    // Editor project
    async createEditorProject({ name }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalEditorProject(data)

      return data
    },
    async updateEditorProject({ id, name }) {
      // Eager
      this.upsertLocalEditorProject({ id, name })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          editor_project_id: id,
          name
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalEditorProject(data)

      return data
    },
    async deleteEditorProject({ id }) {
      // Eager
      this.deleteLocalEditorProject({ id })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          editor_project_id: id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listEditorProjects() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.editor_projects = data
    },
    upsertLocalEditorProject(editor_project) {
      const index = this.editor_projects.findIndex(
        (i) => i.id === editor_project?.id
      )
      if (index >= 0) {
        if (editor_project?.name) {
          this.editor_projects[index].name = editor_project?.name
        }
      } else {
        this.editor_projects.push(editor_project)
      }
    },
    deleteLocalEditorProject({ id }) {
      const index = this.editor_projects.findIndex(
        (editor_project) => editor_project.id === id
      )
      if (index !== -1) {
        this.editor_projects.splice(index, 1)
      }
    },

    // Editor project file
    async createEditorProjectFile({ editor_project_id, name, metadata }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project-file', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          editor_project_id,
          name,
          metadata
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalEditorProjectFile(data)
      console.log('DEBUG AAA', data)

      return data
    },
    async createEditorProjectFileCopy({ editor_project_id, file }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project-file-copy', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          editor_project_id,
          file
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalEditorProjectFile(data)

      return data
    },
    async deleteEditorProjectFile({ id }) {
      // Eager
      this.deleteLocalEditorProjectFile({ id })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/editor-project-file', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          editor_project_file_id: id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalEditorProjectFile(editor_project_file) {
      // First, find editor project
      const editorProjectIndex = this.editor_projects.findIndex(
        (i) => i.id === editor_project_file?.editor_project_id
      )

      if (editorProjectIndex !== -1) {
        // Then, find editor project file
        const editorProjectFileIndex = this.editor_projects[
          editorProjectIndex
        ].editor_project_files.findIndex(
          (i) => i.id === editor_project_file?.id
        )

        if (editorProjectFileIndex !== -1) {
          this.editor_projects[editorProjectIndex].editor_project_files[
            editorProjectFileIndex
          ] = editor_project_file
        } else {
          this.editor_projects[editorProjectIndex].editor_project_files.push(
            editor_project_file
          )
        }
      }
    },
    deleteLocalEditorProjectFile({ id }) {
      // Iterate over each editor project
      this.editor_projects.forEach((editor_project) => {
        // Find the index of the editor project file with the given id
        const fileIndex = editor_project.editor_project_files.findIndex(
          (file) => file.id === id
        )

        // If the file is found, remove it from the editor project files array
        if (fileIndex !== -1) {
          editor_project.editor_project_files.splice(fileIndex, 1)
        }
      })
    },

    // Tool
    async createTool({ editor_project_file_id, tool, input, dry_run }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/tool', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          editor_project_file_id,
          tool,
          input,
          dry_run
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalEditorProjectFile(data)

      return data
    },

    // Wildcards
    async createWildcard({ name, content }) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/wildcard', {
        method: 'POST',
        body: {
          team_id: this.selected_team_id,
          name,
          content
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.upsertLocalWildcard(data)

      return data
    },
    async updateWildcard({ id, name, content }) {
      // Eager
      this.upsertLocalWildcard({ id, name, content })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/wildcard', {
        method: 'PATCH',
        body: {
          team_id: this.selected_team_id,
          wildcard_id: id,
          name,
          content
        }
      })

      if (error) {
        throw new Error(error)
      }

      // this.upsertLocalWildcard(data)

      return data
    },
    async deleteWildcard({ id }) {
      this.deleteLocalWildcard({ id })

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/wildcard', {
        method: 'DELETE',
        body: {
          team_id: this.selected_team_id,
          wildcard_id: id
        }
      })

      if (error) {
        throw new Error(error)
      }

      return data
    },
    async listWildcards() {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/wildcard', {
        query: {
          team_id: this.selected_team_id
        }
      })

      if (error) {
        throw new Error(error)
      }

      this.wildcards = data
    },
    upsertLocalWildcard(wildcard) {
      const index = this.wildcards.findIndex((i) => i.id === wildcard?.id)
      if (index !== -1) {
        if (wildcard?.name) {
          this.wildcards[index].name = wildcard?.name
        }
        if (wildcard?.content) {
          this.wildcards[index].content = wildcard?.content
        }
      } else {
        this.wildcards.push(wildcard)
      }
    },
    deleteLocalWildcard({ id }) {
      const index = this.wildcards.findIndex((wildcard) => wildcard.id === id)
      if (index !== -1) {
        this.wildcards.splice(index, 1)
      }
    }
  },
  getters: {
    personal_team_id: (state) =>
      state.user?.user_metadata?.personal_team_id || null,
    personal_team: (state) =>
      state.teams.find(
        (team) => team.id === state.user?.user_metadata?.personal_team_id
      ) || null,
    selected_team: (state) =>
      state.teams.find((team) => team.id === state.selected_team_id) || null,
    folder_tree: (state) => {
      // Create a map to store all folders by their id
      const folderMap = new Map(
        state.folders.map((folder) => [folder.id, { ...folder, children: [] }])
      )

      // Array to store root-level folders
      const rootFolders = []

      // Iterate through all folders to build the tree structure
      for (const folder of folderMap.values()) {
        if (folder.inode_id === null) {
          // This is a root folder
          rootFolders.push(folder)
        } else {
          // This folder has a parent
          const parent = folderMap.get(folder.inode_id)
          if (parent) {
            parent.children.push(folder)
          } else {
            console.warn(
              `Parent with id ${folder.inode_id} not found for folder ${folder.id}`
            )
          }
        }
      }

      // Sort children arrays alphabetically by name
      const sortChildren = (folder) => {
        folder.children.sort((a, b) => a.name.localeCompare(b.name))
        folder.children.forEach(sortChildren)
      }

      rootFolders.forEach(sortChildren)

      // Sort root folders alphabetically by name
      rootFolders.sort((a, b) => a.name.localeCompare(b.name))

      return rootFolders
    },
    // Playground files are a mix of predictions and files
    playground_files: (state) => {
      return state.predictions
        .map((prediction) => {
          // Reference file if possible
          const file = state.files.find(
            (file) => file.id === prediction?.file_id
          )
          return file || prediction
        })
        .filter((prediction) => {
          if (prediction?.progress) {
            return prediction.progress < 100
          } else {
            return true
          }
        })
    }
  }
})
